$primary: #D9556D; /* MAIN COLOR */
$secondary: #273273; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Heebo:900|Lato');

.flash {
	display:none;
}
p {
	font-size: 1.3em;
	font-family: 'Lato', sans-serif;
}
nav {
	z-index: 1000;
}

h1,h2,h3 {
	font-family: 'Heebo', sans-serif;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 30px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }

		&:focus {
		  background: transparent;
		  color: #777;
		  outline: 0;
		}
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 15px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


@media(max-width: 767px) {
  .navbar-header a {
    float: left;
  } 
}

.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}

.banner	{
	background: url(../img/banner.jpg) no-repeat;
	background-size: cover;
	height: 600px;
	background-position: 50% 70%;
	position: relative;
	h1 {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		position: absolute;
		background: rgba(0,0,0,0.5);
		padding: 10px;
		display: none;
		@media (max-width: 991px) {
			display: block;
		}
	}
	.iphone {
	max-width: 450px;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 15%;
	@media (max-width: 1100px) {
		left: 0;
	}
	@media (max-width: 991px) {
		display: none;
	}
	}
	@media (max-width: 1350px) {
		background-position: 100% 50%;
	}
	@media (max-width: 1024px) {
		background-position: 80% 50%;
		height: auto;
		padding: 250px 0;
	}
	@media (max-width: 767px) {
		padding: 150px 0;
	}
}

.introSection {
	p {
		font-size: 1.3em;
	}
}
.bg {
	background: linear-gradient(
      rgba($primary, 0.7),
      rgba($secondary, 0.7)
		),url(../img/banner2.jpg) no-repeat;
	background-size: cover;
	height: 400px;
	position: relative;
	@media (max-width: 1200px) {
		background-position: 55% 50%;
	}
	@media (max-width: 568px) {
		background-position: 75% 50%; 
	}
	h1 {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		color: white;
		font-size: 3.5em;
	}
	h2 {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		color: white;
		font-size: 2em;
		padding: 0;
		margin: 0;
		text-align: center;
	}
}

.bg2 {
	background: linear-gradient(
      rgba($primary, 0.8),
      rgba($primary, 0.8)
		),url(../img/banner3.jpg) no-repeat;
	background-size: cover;
	background-attachment: fixed;
	height: 500px;
	background-position: 50% 50%;
	padding-top: 150px;
	@media (max-width: 991px) {
		height: auto;
		padding-top: 0;
	}
	.box1 {
		background: url(../img/message.jpg) no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		height: 200px;
		position: relative;
		@media (max-width: 991px) {
			height: 300px;
		}
		.innerTxt {
			background: $primary;
			display: inline-block;
			padding: 10px 30px;
			position: absolute;
			bottom: 10%;
			left: 5%;
			color: white;
			p {
				margin: 0;
			}
		}
	}
	.box2 {
		background: url(../img/chat.jpg) no-repeat;
		background-size: cover;
		background-position: 50% 30%;
		height: 200px;
		position: relative;
		@media (max-width: 991px) {
			height: 300px;
		}
		.innerTxt {
			background: $primary;
			display: inline-block;
			padding: 10px 30px;
			position: absolute;
			bottom: 10%;
			left: 5%;
			color: white;
			p {
				margin: 0;
			}
		}
	}
	.box3 {
		background: url(../img/love.jpg) no-repeat;
		background-size: cover;
		height: 200px;
		position: relative;
		@media (max-width: 991px) {
			height: 300px;
		}
		@media (max-width: 600px) {
			background-position: 60% 50%;
		}
		.innerTxt {
			background: $primary;
			display: inline-block;
			padding: 10px 30px;
			position: absolute;
			bottom: 10%;
			left: 5%;
			color: white;
			p {
				margin: 0;
			}
		}
	}
}

.padTopBtm {
	padding: 75px 15px;
}

.sideImg1 {
	background: linear-gradient(
       rgba(0,0,0,0.5),
       rgba(0,0,0,0.5)
		),url(../img/sideImg1.jpg) no-repeat;
	background-size: cover;
	height: 250px;
	background-origin: content-box;
	background-position: 50% 55%;
	transition: all 0.5s ease-in-out;
	border: 1px solid white;
	position: relative;
	&:hover {
		border: 20px solid white;
	}
	h1 {
		color: white;
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%,-50%);
		font-size: 1.8em;
		padding: 0;
		margin: 0;
		text-align: center;
		@media (max-width: 767px) {
			font-size: 1.5em;
		}
	}
}
.sideImg2 {
	background: linear-gradient(
         rgba($primary, 0.5),
         rgba($primary, 0.5)
		),url(../img/sideImg2.jpg) no-repeat;
	background-size: cover;
	height: 250px;
	background-origin: content-box;
	background-position: 50% 20%;
	transition: all 0.5s ease-in-out;
	border: 1px solid white;
	&:hover {
		border: 20px solid white;
	}
	h1 {
		color: white;
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%,-50%);
		font-size: 1.8em;
		padding: 0;
		margin: 0;
		@media (max-width: 767px) {
			font-size: 1.5em;
		}
	}
}
.sideImg3 {
	background: linear-gradient(
        rgba($secondary, 0.7),
        rgba($secondary,0.7)
		),url(../img/sideImg3.jpg) no-repeat;
	background-size: cover;
	height: 530px;
	background-origin: content-box;
	background-position: 50% 50%;
	border: 1px solid white;
	transition: all 0.5s ease-in-out;
	@media (max-width: 767px) {
		height: 300px;
		background-position: 50% 40%;
	}
	&:hover {
		border: 20px solid white;
	}
	h1 {
		color: white;
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%,-50%);
		font-size: 1.8em;
		padding: 0;
		margin: 0;
		@media (max-width: 767px) {
			font-size: 1.5em;
		}
	}
}

.boxPad {
	padding-left: 15px;
	padding-right: 15px;
	margin: 0;
}
.boxPad2 {
	padding-bottom: 30px;
}

a.btn {
	@include btn1;
}

.logo {
	max-width: 200px;
	width: 100%;
	margin-top: 10px;
	@media (max-width: 767px) {
		max-width: 150px;
	}
}

footer a:hover, footer a:focus {
	outline: 0;
	text-decoration: none;
	color: white;
}